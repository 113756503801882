import styled, { css } from 'styled-components';
import * as colors from '../colors';
import { DEFAULT_FONT_FAMILY } from './base';
import { createResponsiveFontRules } from '../functions';

// 100: thin
// 300: light
// 400: regular
// 500: medium
// 600: semibold
// 700: bold
// 800: extrabold
// 900: black

export const textTypeMap = {
    body: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
    },
    eyebrow: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        letterSpacing: '.5px',
        textTransform: 'uppercase'
    },
    caption: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 300,
    },
    footnote: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 300,
    },
    button: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 500,
    },
    subtitle: {
        fontFamily: "'proxima-nova', Helvetica, sans-serif",
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: 300,
    },
    data1: {
        fontFamily: "'proxima-nova', Helvetica, sans-serif",
        fontSizeAndLineHeight: [60, 50, 80, 60],
        fontWeight: 300,
    },
    data2: {
        fontFamily: "'proxima-nova', Helvetica, sans-serif",
        fontSizeAndLineHeight: [35, 26, 44, 32],
        fontWeight: 300,
    },
};

export const getTextStylesByType = textType => css`
    font-family: ${DEFAULT_FONT_FAMILY};
    font-weight: ${textTypeMap[textType].fontWeight};
    ${(textTypeMap[textType].fontSizeAndLineHeight) ?
        createResponsiveFontRules(...textTypeMap[textType].fontSizeAndLineHeight)
    :
        `font-size: ${textTypeMap[textType].fontSize}`
    };
    line-height: ${textTypeMap[textType].lineHeight};
    color: ${({ theme }) => {
        return theme === 'light' ? colors.white : colors.charcoal;
    }};
    letter-spacing: ${textTypeMap[textType].letterSpacing};
    text-transform: ${textTypeMap[textType].textTransform};
`;

export const getLinkStyles = css`
    a {
        color: ${({ theme }) => (theme === 'light' ? colors.sky : colors.blueberry)};
        text-decoration: none;
        transition: color 0.3s ease-in-out;
    }

    a:hover,
    a:active,
    a:focus {
        color: ${({ theme }) => (theme === 'light') ? colors.skyLight : colors.blueberryDark};
        cursor: pointer;
    }
`;

// this wasn't previously being applied; 500 may not be "bold" enough
export const getBoldStyles = css`
    b,
    strong {
        font-weight: 500;
        color: ${({ theme }) => (theme === 'light') ? colors.white : colors.ink};
    }
`;

export const getBodySuperscriptStyles = css`
    sup {
        font-size: 10px;
        vertical-align: top;
        position: relative;
        top: 0.5em;
    }
`;

export const getSmalltextSuperscriptStyles = css`
    sup {
        font-size: 8px;
        vertical-align: top;
        position: relative;
        top: 0.9em;
    }
`;

export const getDataSuperscriptStyles = css`
    sup {
        font-size: max(42%, 10px);
        vertical-align: top;
        position: relative;
        top: 0.2em;
    }
`;

export const Body = styled.span`
    ${getTextStylesByType('body')}
    ${getLinkStyles}
    ${getBoldStyles}
    ${getBodySuperscriptStyles}
`;

export const Eyebrow = styled.span`
    ${getTextStylesByType('eyebrow')}
    ${getSmalltextSuperscriptStyles}
`;

export const Caption = styled.span`
    ${getTextStylesByType('caption')}
    ${getLinkStyles}
    ${getSmalltextSuperscriptStyles}
`;

export const Footnote = styled.span`
    ${getTextStylesByType('footnote')}
    ${getLinkStyles}
    ${getBoldStyles}
    ${getSmalltextSuperscriptStyles}
`;

export const Button = styled.span`
    ${getTextStylesByType('button')}
`;

export const Subtitle = styled.span`
    ${getTextStylesByType('subtitle')}
`;

export const Data1 = styled.span`
    ${getTextStylesByType('data1')}
    ${getDataSuperscriptStyles}

`;

export const Data2 = styled.span`
    ${getTextStylesByType('data2')}
    ${getDataSuperscriptStyles}
`;
