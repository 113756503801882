import styled from 'styled-components';
import * as colors from '../colors';
import {
    caratRightSkinnyBlueberry,
    caratRightSkinnyPebble,
} from '../icons';

const SliderButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    border: 1px solid ${colors.pebble};
    border-radius: 25px;
    background-color: ${colors.white};
    cursor: pointer;

    &::after {
            display: block;
            content: '';
            width: 44px;
            height: 44px;
            background-image: url(${caratRightSkinnyBlueberry});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 20px;
            margin-left: 1px; // visually centers the right-carret (per design direction)
        }
    

     //render hover states on non-touch devices
     @media (hover: hover) {
        &:hover,
        &:active,
        &:focus {
            background-color: ${colors.mist};
            border: solid 1px ${colors.mist};
        }
    }

    &[disabled] {
        pointer-events: none;


        &::after {
            background-image: url(${caratRightSkinnyPebble});
        }
    }
`;

export default SliderButton;
