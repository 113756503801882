import React from 'react';
import styled from 'styled-components';
import * as Text from './Text';

const FootnoteWrapper = styled.div`
    display: flex;
    margin-bottom: .5em;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Label = styled(Text.Footnote)`
    flex: 0 0 30px;
`;

export const Footnote = props => {
    return (
        <FootnoteWrapper>
            <Label theme={props.theme}>{props.label}</Label>{' '}
            <Text.Footnote theme={props.theme} dangerouslySetInnerHTML={{__html: props.text}} />
        </FootnoteWrapper>
    );
};
