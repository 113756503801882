import styled from 'styled-components';
import * as colors from '../colors';
import {
    downloadBarWhite,
    downloadArrowWhite,
    externalDotWhite,
    externalArrowWhite,
} from '../icons';
import { splitGradient } from './PrimaryButton';

const SecondaryButton = styled.a.attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category']
}))`
    padding: 0;
    cursor: pointer;
    background-image: ${({ theme }) =>
        theme === 'light'
            ? splitGradient('transparent', colors.white)
            : splitGradient(colors.white, colors.blueberry)};
    background-size: 201% 100%;
    background-origin: border-box;
    border: 1px solid transparent;
    border-color: ${({ theme }) => (theme === 'light' ? colors.white : colors.blueberry)};
    color: ${({ theme }) => (theme === 'light' ? colors.white : colors.blueberry)};
    display: flex;
    justify-content: center;
    align-items: stretch;
    transition: border-radius 0.2s ease-in-out, background-position 0.2s ease-in-out,
        color 0.2s ease-in-out;
    overflow: hidden;
    text-decoration: none;
    border-radius: 4px;
    margin: 0;

    &:hover,
    &:active,
    &:focus {
        color: ${({ theme }) => (theme === 'light' ? colors.blueberry : colors.white)};
        background-position: right;
    }

    > span {
        padding: 0.8em 22px 0.7em;
    }

    &[download] {
        &::after {
            display: block;
            content: '';
            width: 44px;
            background-color: ${colors.blueberry};
            background-image: url(${downloadBarWhite}), url(${downloadArrowWhite});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px 12px;
        }
        //render hover states on non-touch devices
        @media (hover: hover) {
            &:hover&::after {
                animation-duration: 0.3s;
                animation-name: download-animation;
            }
        }
    }

    @keyframes download-animation {
        0%,
        100% {
            background-position: center, center 50%;
        }

        50% {
            background-position: center, center calc(100% + 15px);
        }

        50.001% {
            background-position: center, center calc(0% - 15px);
        }
    }

    &[disabled] {
        background-image: none;
        background-color: ${colors.white};
        pointer-events: none;
        color: ${colors.slate};
        border-color: ${colors.slate};

        &[download]&::after {
            background-color: ${colors.slate};
        }

        ${({ external }) =>
            external &&
            `
            &::after {
                background-image: url(${externalDotWhite}), url(${externalArrowWhite});
                background-color: ${colors.slate};
            }
        `}
    }

    ${({ external }) =>
        external &&
        `
        &::after {
            display: block;
            content: "";
            width: 44px;
            background-color: ${colors.blueberry};
            background-image: url(${externalDotWhite}), url(${externalArrowWhite});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px 10px;
        }

        &:hover {
            &::after {
                animation-duration: .4s;
                animation-name: external-animation;
            }
        }
    `}

    @keyframes external-animation {
        0%,
        100% {
            background-position: center, center 50%;
        }

        50% {
            background-position: center, calc(100% + 10px) calc(0% - 10px);
        }

        50.001% {
            background-position: center, calc(0% - 10px) calc(100% + 10px);
        }
    }
`;

export default SecondaryButton;
