import styled, { css } from 'styled-components';
import * as colors from '../colors';
import { getLinkStyles, getBodySuperscriptStyles } from './Text';
import { DEFAULT_FONT_FAMILY } from './base';

export const getListStyles = css`
    font-family: ${DEFAULT_FONT_FAMILY};
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    list-style: none;
    padding: 0;
    color: ${({ theme }) => (theme === 'dark' ? colors.white : colors.charcoal)};

    ${getLinkStyles}
    ${getBodySuperscriptStyles}
`;

export const getOrderedListStyles = css`
    li {
        counter-increment: olCounter;
 
        padding-left: 1.8em;
        margin: 10px 0;
        color: ${({ theme }) => {
            return theme === 'light' ? colors.white : colors.charcoal;
        }};

        &::before {
            content: counter(olCounter) '.';
            display: inline-block;
            margin: 0 0.8em 0 -1.8em;
            width: 1em;
            color: ${colors.keytrudaGreenWeb}
        }

        ol {
            
           
            li {
                counter-increment: olSubCounter;

                &::before {
                    content: counter(olSubCounter, upper-alpha) '.';
                }
            }
        }
    }
`;

export const getUnorderedListStyles = css`
    li {
        padding-left: calc(8px + 1.2em);
        margin: 10px 0;
        color: ${({ theme }) => {
            return theme === 'light' ? colors.white : colors.charcoal;
        }};

        &::before {
            content: '';
            width: 8px;
            height: 2px;
            background-color: ${colors.keytrudaGreen};
            display: inline-block;
            margin: 0 1.2em 0 calc(-8px - 1.2em);
            vertical-align: middle;
        }

        ul li {
            padding-left: calc(4px + 1.2em);
        }

        ul li::before {
            width: 4px;
            height: 4px;
            border-radius: 2px;
            margin-left: calc(-4px - 1.2em);
        }

        ul ul li::before {
            background-color: transparent;
            border: 1px solid ${colors.keytrudaGreen};
        }
    }
`;

export const Number = styled.ol`
    ${getListStyles}
    ${getOrderedListStyles}
`;

export const Bullet = styled.ul`
    ${getListStyles}
    ${getUnorderedListStyles}
`;
