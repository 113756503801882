import styled from 'styled-components';
import { getHeadingStylesByType } from './Heading';
import { getTextStylesByType, getLinkStyles, getBoldStyles, getSmalltextSuperscriptStyles, getBodySuperscriptStyles } from './Text';
import { getListStyles, getOrderedListStyles, getUnorderedListStyles } from './List';
import { between } from 'polished';

const WYSIWYG = styled.div`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
    }
    h1,
    .h1 {
        ${getHeadingStylesByType('1')}
    }
    h2,
    .h2 {
        ${getHeadingStylesByType('2')}
    }
    h3,
    .h3 {
        ${getHeadingStylesByType('3')}
    }
    h4,
    .h4 {
        ${getHeadingStylesByType('4')}
    }

    h5,
    .h5 {
        ${getHeadingStylesByType('5')}
        margin-top: clamp(20px, 2.5vw, 30px); 
        margin-bottom: 16px;
    }
    h6,
    .h6 {
        margin-top: 30px;
        margin-bottom: 10px;
        ${getHeadingStylesByType('6')}

        @media (min-width: 321px) and (max-width: 1032px) {
            margin-top: ${between('30px', '40px', '320px', '1032px')};
        }

        @media (min-width: 1033px) {
            margin-top: 40px;
        }
    }

    .h7 {
        ${getHeadingStylesByType('7')}
    }

    .xl {
        ${getHeadingStylesByType('XL')}
    }

    .subtitle {
        ${getHeadingStylesByType('subtitle')}
    }

    p,
    .p,
    .body {
        ${getTextStylesByType('body')}
        ${getLinkStyles}
        ${getBoldStyles}
        margin: 1em auto;
    }

    .eyebrow {
        ${getTextStylesByType('eyebrow')}
    }

    .caption {
        ${getTextStylesByType('caption')}
        ${getLinkStyles}
        ${getSmalltextSuperscriptStyles}
    }

    .footnote {
        ${getTextStylesByType('footnote')}
        ${getLinkStyles}
        ${getSmalltextSuperscriptStyles}
    }

    .button {
        ${getTextStylesByType('button')}
    }

    p,
    .p,
    .body,
    .eyebrow,
    .caption,
    .footnote,
    .button {
        display: block;
    }

    ol,
    ul {
        ${getListStyles}
    }

    ol {
        ${getOrderedListStyles}
    }
    ul {
        ${getUnorderedListStyles}
    }

    /* default is body? */
    ${getTextStylesByType('body')}
    ${getLinkStyles}
    ${getBoldStyles}
    ${getBodySuperscriptStyles}

    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

export default WYSIWYG;
