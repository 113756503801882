import React from 'react';
import styled from 'styled-components';
import * as Text from './Text';

const DefWrapper = styled.span`

`;

const Def = styled(Text.Footnote)`
    display: inline;
    margin-left: 0;
`;

export const DefinitionBlock = props => {
    return (
        <DefWrapper>
            <Def theme={props.theme} dangerouslySetInnerHTML={{__html: props.text }} />
        </DefWrapper>
    );
};
