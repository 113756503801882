import styled from 'styled-components';
import * as colors from '../colors';
import {
    arrowRightBlueberry,
    arrowRightWhite,
    downloadArrowBlueberry,
    downloadArrowWhite,
    downloadBarBlueberry,
    downloadBarWhite,
    externalArrowBlueberry,
    externalArrowWhite,
    externalDotBlueberry,
    externalDotWhite,
} from '../icons';

const splitGradient = (leftColor, rightColor) =>
    `linear-gradient(to right, ${leftColor} 0%, ${leftColor} 50%, ${rightColor} calc(50% + 1px), ${rightColor} 100%)`;

const TertiaryButton = styled.a.attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category']
}))`
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: ${({ theme }) => (theme === 'light' ? colors.white : colors.blueberry)};
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    text-decoration: none;
    
    > span {
        padding: 0.9em 0;
        text-align: left;
    }

    &::after {
        flex: 0 0 20px;
        display: inline-block;
        content: '';
        background-image: ${({ theme }) =>
            theme === 'light'
                ? `url(${arrowRightBlueberry}), ${splitGradient(colors.white, colors.white)}`
                : `url(${arrowRightWhite}), ${splitGradient(
                      colors.blueberry,
                      colors.blueberryDark
                  )}`};
        background-repeat: no-repeat;
        background-position: center, left;
        background-size: 10px 10px, 200% 100%;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-left: 6px;
    }

    &[download] {
        ::after {
            display: inline-block;
            content: '';
            background-image: ${({ theme }) =>
                theme === 'light'
                    ? `url(${downloadBarBlueberry}), url(${downloadArrowBlueberry}), ${splitGradient(
                          colors.white,
                          colors.white
                      )}`
                    : `url(${downloadBarWhite}), url(${downloadArrowWhite}), ${splitGradient(
                          colors.blueberry,
                          colors.blueberryDark
                      )}`};
            background-position: center, center, left;
            background-size: 10px 10px, 10px 10px, 200% 100%;
        }

        &:hover,
        &:active,
        &:focus {
            &::after {
                animation-name: download-animation;
            }
        }

        ${({ theme, parentHovered }) =>
            parentHovered &&
            `@media (hover: hover) {
                &::after {
                    animation-name: download-animation;
                }
            }
        `};
    }
     //render hover states on non-touch devices
     @media (hover: hover) {
        &:hover,
        &:active,
        &:focus {
            color: ${({ theme }) => (theme === 'light' ? colors.white : colors.blueberryDark)};

            &::after {
                animation-duration: 0.3s;
                animation-name: arrowRight-animation;
                animation-fill-mode: both;
            }
        }
    }

    ${({ theme, parentHovered }) =>
        parentHovered &&
        `@media (hover: hover) {
            color: ${theme === 'light' ? colors.white : colors.blueberryDark};

            &::after {
                animation-duration: .3s;
                animation-name: arrowRight-animation;
                animation-fill-mode: both;
            }
        }
    `}

    @keyframes arrowRight-animation {
        0% {
            background-position: center 50%, left;
        }

        50% {
            background-position: calc(100% + 20px) center, center;
        }

        50.001% {
            background-position: calc(0% - 20px) center, center;
        }

        100% {
            background-position: center 50%, right;
        }
    }

    @keyframes download-animation {
        0% {
            background-position: center, center 50%, left;
        }

        50% {
            background-position: center, center calc(100% + 15px), center;
        }

        50.001% {
            background-position: center, center calc(0% - 15px), center;
        }

        100% {
            background-position: center, center 50%, right;
        }
    }

    &[disabled] {
        pointer-events: none;
        color: ${colors.slate};

        &::after {
            background-image: url(${arrowRightWhite});
            background-color: ${colors.slate};
        }

        &[download]&::after {
            background-image: url(${downloadBarWhite}), url(${downloadArrowWhite});
        }
    }

    ${({ external, theme, parentHovered }) =>
        external &&
        `
        &::after {
            display: block;
            content: "";
            width: 44px;
            
            background-image: ${
                theme === 'light'
                    ? `url(${externalDotBlueberry}), url(${externalArrowBlueberry}), ${splitGradient(
                          colors.white,
                          colors.white
                      )}`
                    : `url(${externalDotWhite}), url(${externalArrowWhite}), ${splitGradient(
                          colors.blueberry,
                          colors.blueberryDark
                      )}`
            };
            background-repeat: no-repeat;
            border-radius: 50%;
            background-position: center, center, left;
            background-size: 10px 10px, 10px 10px, 200% 100%;
            width: 20px;
            height: 20px;
        }
        @media (hover: hover) {
            &:hover,
            &:active,
            &:focus {
                &::after {
                    animation-duration: .4s;
                    animation-name: external-animation;
                }
            }
        }

        ${
            parentHovered &&
            `@media (hover: hover) {
                &::after {
                    animation-duration: .4s;
                    animation-name: external-animation;
                }
            }`
        }
    `}

    @keyframes external-animation {
        0% {
            background-position: center, center 50%, left;
        }

        50% {
            background-position: center, calc(100% + 10px) calc(0% - 10px), center;
        }

        50.001% {
            background-position: center, calc(0% - 10px) calc(100% + 10px), center;
        }

        100% {
            background-position: center, center 50%, right;
        }
    }
`;

export default TertiaryButton;
