import styled from 'styled-components';
import { between } from 'polished';

const ComponentWrapper = styled.div`
    margin: 30px auto;
    max-width: 992px; // this property is largely here for convenience in DSM/Storybook. Properly speaking, content components should expand to fill whatever container they are in - they have no real 'max-width'. However, when components are being reviewed in isolation in Storybook/DSM, designers/other non-devs are being repeatedly confused as to why the content components are able to grow so much more than they appear in the 'desktop comps'. Since our defined max-width for any layout is 992px, I included this here as it a) doesn't affect the nesting of design system components *in practice*, and b) alleviates the need to constantly re-explain the difference between content and layout components. We can deprecate this at any time.

    @media (min-width: 320px) and (max-width: 1032px) {
        margin: ${between('30px', '40px', '320px', '1032px')} auto;
    }

    @media (min-width: 1033px) {
        margin: 40px auto;
    }
`;

export default ComponentWrapper;
