import React from 'react';
import { Text } from '../typography';
import Primary from './PrimaryButton';
import Secondary from './SecondaryButton';
import Tertiary from './TertiaryButton';
import SliderCaret from './SliderButton';
import Link from 'gatsby-link';
import { createMarkup } from '../functions';

// @TODO check for more than pdfs to apply download prop
// @TODO strip out 'http://keytrudahcp.com' if it's entered for local links

export const setPropsFromURL = url => {
    if (!url) {
        return;
    }

    let props = {};

    //strip the domain if it's local
    let regex = /^(http|https):\/\/keytrudahcp.com/
    url.replace(regex, '');

    if (url.endsWith('.pdf')) { //download
        props.href = url;
        props.download = true;
        props.target = '_blank';
        props.as = 'a';
    } else if (url.startsWith('http')) { //external
        props.href = url;
        props.external = true;
        props.target = '_blank';
        props.as = 'a';
    } else { //local, use Gatsby Link for local links
        props.to = url;
        props.as = Link;
    }

    return props;
};

//@TODO remove the url prop so it doesn't print

export const PrimaryButton = ({tagType, ...props}) => {
    return (
        <Primary {...setPropsFromURL(props.url)} {...props} data-design-category='button_vd' {...(tagType && { as: tagType })}>
            <Text.Button style={{ color: 'inherit' }} dangerouslySetInnerHTML={createMarkup(props.label)} />
        </Primary>
    );
};

export const SecondaryButton = ({tagType, ...props}) => {
    return (
        <Secondary {...setPropsFromURL(props.url)} {...props} data-design-category='button_vd' {...(tagType && { as: tagType })}>
            <Text.Button style={{ color: 'inherit' }} dangerouslySetInnerHTML={createMarkup(props.label)} />
        </Secondary>
    );
};

export const TertiaryButton = ({tagType, ...props}) => {
    return (
        <Tertiary {...setPropsFromURL(props.url)} {...props} data-design-category='button_vd' {...(tagType && { as: tagType })}>
            <Text.Button style={{ color: 'inherit' }} dangerouslySetInnerHTML={createMarkup(props.label)} />
        </Tertiary>
    );
};

export const SliderButton = props => {
    return (
        <SliderCaret {...setPropsFromURL(props.url)} {...props} data-design-category='button_vd'/>
    );
};
